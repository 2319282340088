.App {
  text-align: center;
  display: flex;
}

.App-logo {
  pointer-events: none;
  max-width: 80%;
  max-height: 80%;
}

.App-header {
  width: 50%;
  background-color: #6369D1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  width: 100%;
}

.right {
  background-color: white;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
}

.screenshot {
  max-width: 80%;
  max-height: 80%;
  position: relative;
  box-shadow: 0px 70px 40px -40px rgba(0,0,0,.5);
  border-radius: 50px;
}
